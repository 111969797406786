<template>
  <b-card>
    <form>
      <template v-if="!intially_loaded">Checking Login Status....</template>
      <template v-else-if="login_status_error">{{login_status_error}}</template>
      <template v-else-if="!isLoggedIn">
        <div class="form-group">
          <label for="managerUsername">Brilliant Manager Username</label>
          <input
            type="text"
            autocomplete="username"
            class="form-control form-control-sm"
            id="managerUsername"
            v-model.trim.lazy="manager_username"
            placeholder="Brilliant Manager Username"
          />
        </div>
        <div class="form-group">
          <label for="managerPassword">Brilliant Manager Password</label>
          <input
            type="password"
            autocomplete="current-password"
            class="form-control form-control-sm"
            id="managerPassword"
            v-model.trim.lazy="manager_password"
            placeholder="Brilliant Manager Password"
            v-on:keyup.enter="loginManager"
          />
        </div>
        <b-button
          variant="secondary"
          v-on:click.stop.prevent="loginManager"
          v-bind:disabled="loading"
        >
          <template v-if="loading">
            <b-spinner small type="grow"></b-spinner>Loading...
          </template>
          <template v-else>Login</template>
        </b-button>
        {{ login_error_message }}
      </template>
      <template v-else>
        Logged in to Manager as user:
        <span class="username">{{ userName }}</span>
        <b-link href="#" v-on:click.stop.prevent="logoutManager"
          >(Log out)</b-link
        >
        <my-portals-list></my-portals-list>
      </template>
    </form>
  </b-card>
</template>
<style>
span.username {
  font-weight: bold;
  margin: 0 0.5em;
}

ul {
  list-style: none;
}
</style>
<script>
import qs from "qs";

import MyPortalsList from "../components/MyPortalsList.vue";

export default {
  components: {
    MyPortalsList,
  },
  name: "ManagerLoginStatus",
  data: () => {
    return {
      manager_username: "",
      manager_password: "",
      login_error_message: "",
      loading: false,
      intially_loaded: false,
      login_status_error:false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.manager.isLoggedIn;
    },
    userName() {
      return this.$store.state.manager.userName;
    },
  },
  mounted() {
    if (
      process.env.NODE_ENV == "development" &&
      process.env.VUE_APP_FUDGE_STATUS_CHECK == "TRUE"
    ) {
      this.$store.commit("updateManagerLoginState", {
        success: true,
        user_data: { user_name: "Mock Dev User" },
      });
      this.intially_loaded = true;
    } else {
      this.checkManagerLoginStatus();
    }
  },
  methods: {
    checkManagerLoginStatus: function () {
      console.log("Checking Login Status");
      this.loading = true;
      var that = this;
      const options = {
        method: "GET",
        url:
          process.env.VUE_APP_MANAGER_SERVER_URL + "/user/spaces/login_status",
        withCredentials: true,
      };
      this.$http(options)
        .then(function (response) {
          console.log(response);
          that.intially_loaded = true;
          that.$store.commit("updateManagerLoginState", response.data);
          that.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          that.loading = false;
          that.intially_loaded = true;
          that.login_status_error = "Error fetching sign-in status.  Please try reloading or contact the admin if the issue persists";
        });
    },
    logoutManager: function () {
      console.log("logging out");
      this.loading = true;
      var that = this;
      const options = {
        method: "GET",
        url: process.env.VUE_APP_MANAGER_SERVER_URL + "/account/logout_ajax",
        withCredentials: true,
      };
      this.$http(options)
        .then(function () {
          that.$store.commit("updateManagerLoginState", false);
          that.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          that.loading = false;
        });
    },
    loginManager: function () {
      console.log("logging in");
      this.loading = true;
      var that = this;
      const data = {
        login_string: this.manager_username,
        login_pass: this.manager_password,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
        url: process.env.VUE_APP_MANAGER_SERVER_URL + "/account/signin",
        withCredentials: true,
      };
      this.$http(options)
        .then(function (response) {
          console.log(response);
          if (response.data.success) {
            that.$store.commit("updateManagerLoginState", response.data);
          } else {
            that.login_error_message = response.data.error;
          }
          that.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          that.loading = false;
        });
    },
  },
};
</script>
