import Vue from 'vue'
import Vuex from 'vuex';
import Axios from 'axios'
Vue.use(Vuex);

import ColorHelpers from "../helpers/ColorHelpers.js";
import linkTreeJSON from "../data/linkTreeJSON.js";


export default new Vuex.Store({
    plugins: [
        function (store) {
            store.subscribe((mutation, state) => {
                // called after every mutation.
                // The mutation comes in the format of `{ type, payload }`.
                //console.log('mutation', mutation, state);
                // Check if it is the portal title and then update in the portals list;
                if (mutation.type == "updatePortalTitle") {
                    // get index of seleted portal
                    var idx = state.manager.selectedPortalIndex;
                    state.manager.savedPortalConfigs[idx].portal_title = mutation.payload;
                }
            });
        },

        function (store) {
            // watch the store for changes on the portal to be able to sync with the server
            store.watch(
                function (state) {
                    return state.portal;
                },
                function (newValue, oldValue) {
                    if (newValue.published) {
                        return;
                    }
                    //do something on data change
                    console.log("'" + oldValue + "'", typeof (oldValue));
                    console.log("'" + newValue + "'", typeof (newValue));

                    if (store.state.manager.portal_is_dirty && (oldValue != '' || (oldValue != '' && newValue != ''))) {
                        // now debounce the changes 
                        console.log("Portal changed", newValue, oldValue);
                        var ongoingRequest = store.state.manager.ongoingRequest;
                        clearTimeout(ongoingRequest);
                        ongoingRequest = setTimeout(function () {
                            store.commit('updateBusy', "Saving Portal Config...");
                            var saveData = new FormData();
                            saveData.append('config', JSON.stringify(newValue));
                            store.commit('savingPortalConfig', true);
                            const options = {
                                method: "POST",
                                data: saveData,
                                url:
                                    process.env.VUE_APP_MANAGER_SERVER_URL +
                                    "/user/spaces/save_portal_config",
                                withCredentials: true,
                            };
                            Axios(options)
                                .then((response) => {
                                    console.log(response.data);
                                    const payload = {
                                        idx: store.state.manager.selectedPortalIndex,
                                        config: newValue,
                                        last_updated: response.data.last_updated
                                    }
                                    store.commit('updateManagerSavedPortalConfigsAtIdx', payload);
                                    store.commit('savingPortalConfig', false);
                                    store.commit('updateBusy', false);
                                })
                                .catch(function (error) {
                                    store.commit('updateBusy', false);
                                    console.log(error);
                                    // @Todo alert user and ask to retry.
                                });
                            console.log('debounced event');
                        }, 1500);
                        store.commit('saveLatestDebounce', ongoingRequest);
                    }
                    store.state.manager.portal_is_dirty = true;
                },
                {
                    deep: true, //add this if u need to watch object properties change etc.
                }
            );
        }
    ],
    state: {
        portal: '',
        initialPortalState: {
            uuid: "",
            customCSS: "",
            portalTitle: "My Portal Title",
            fontColor: "#B00000",
            subHeadingColor: "#000000",
            navRibbonBackgroundColor: "#922d37",
            navRibbonFontColor: "#FFFFFF",
            portalFontStyle: "Arial",
            portalFontSize: "12px",
            backgroundImageSrc: "/assets/img/aerial-view-of-city-lit-up-at-night-325193.jpg",
            backgroundChoice: 'image',
            sitemapBackgroundColor: "#FFFFFF",
            solidBackgroundColor: "#FFFFFF",
            sitemapBackgroundOpacity: 80,
            sitemapCornerRoundingString: "18px",
            sitemapCornerRounding: 18,
            linkTree: linkTreeJSON,
            logoSrc: "/assets/img/brilliant_spaces_logo.svg",
            mainTopicTitleAlignment: "right",
            textStylingBold: "normal",
            textStylingItalic: "normal",
            sidebarCollapsed: false,
            showNavBar: false
        },
        manager: {
            isLoggedIn: false,
            userName: '',
            proxy_list: '',
            savedPortalConfigs: [],
            selectedPortalIndex: undefined,
            ongoingRequest: undefined,
            isBusyBoolean: false,
            isBusyString: ""
        },
        tableau: {
            isLoggedIn: false,
            userName: "",
            loginToken: '',
            siteList: [],
            siteData: []
        },
        templates:[
            {
                template_id:1,
                name:'Template 1 Template 1 Template 1 Template 1 Template 1 Template 1 Template 1 Template 1 Template 1',
                thumbnailUrl:'/assets/img/template_thumbails/template_1.png',
                template_config:{
                    sitemapBackgroundOpacity: 10,
                    fontColor: "green",
                }
            },
            {
                template_id:2,
                name:'Template 2',
                thumbnailUrl:'/assets/img/template_thumbails/template_1.png',
                template_config:{
                    sitemapBackgroundOpacity: 50,
                    fontColor: "blue",}
            },
            {
                template_id:3,
                name:'Template 3',
                thumbnailUrl:'/assets/img/template_thumbails/template_1.png',
                template_config:{}
            },
            {
                template_id:4,
                name:'Template 4',
                thumbnailUrl:'/assets/img/template_thumbails/template_1.png',
                template_config:{}
            }
        ]
    },
    getters: {
        sitemapBackgroundRGBAString: (state) => {
            let rgbObj = ColorHelpers.hexToRgb(state.portal.sitemapBackgroundColor);
            let opacityVal = state.portal.sitemapBackgroundOpacity / 100;
            return 'rgba(' + rgbObj.r + ', ' + rgbObj.g + ', ' + rgbObj.b + ',' + opacityVal + ')';
        },
        backgroundImageSrcCssProp: (state) => {
            return "url(" + state.portal.backgroundImageSrc + ")";
        },
        initialSitemapBackgroundRGBAString: (state) => {
            let rgbObj = ColorHelpers.hexToRgb(state.initialPortalState.sitemapBackgroundColor);
            let opacityVal = state.initialPortalState.sitemapBackgroundOpacity / 100;
            return 'rgba(' + rgbObj.r + ', ' + rgbObj.g + ', ' + rgbObj.b + ',' + opacityVal + ')';
        },
        initialBackgroundImageSrcCssProp: (state) => {
            return "url(" + state.initialPortalState.backgroundImageSrc + ")";
        },
        getUuidIdx: (state) => (uuid) => {
            return state.manager.savedPortalConfigs.findIndex(config => config.uuid === uuid);
        },
        getLinkItemByUrl: (state) => (portalLink) => {
            var matchUrl = decodeURIComponent(portalLink);
            var returnLink = false;
            state.portal.linkTree.forEach(heading => {
                if (returnLink) {
                    return;
                }
                heading.subheadings.forEach(subheading => {
                    if (returnLink) {
                        return;
                    }
                    subheading.links.forEach(link => {
                        if (returnLink) {
                            return;
                        }
                        if (link.url == matchUrl) {
                            returnLink = link;
                        }
                    });
                })
            });
            return returnLink;
        },
        getBreadcrumb: (state) => (portalLink) => {
            var matchUrl = decodeURIComponent(portalLink);
            var breadcrumb = false;
            state.portal.linkTree.forEach(heading => {
                if (breadcrumb) {
                    return;
                }
                heading.subheadings.forEach(subheading => {
                    if (breadcrumb) {
                        return;
                    }
                    subheading.links.forEach(link => {
                        if (breadcrumb) {
                            return;
                        }
                        if (link.url == matchUrl) {
                            breadcrumb = {
                                heading: heading.title,
                                subheading: subheading.title,
                                link_title: link.title
                            }
                        }
                    });
                })
            });
            console.log("Returning breadcrumb", breadcrumb);
            return breadcrumb;
        }

    },
    mutations: {
        updatedPortalUUID(state, uuid) {
            state.portal.uuid = uuid;
        },
        updatePortalTitle(state, newTitle) {
            state.portal.portalTitle = newTitle;
        },
        updateFontColor(state, newColor) {
            state.portal.fontColor = newColor;
        },
        updateSubHeadingColor(state, newColor) {
            state.portal.subHeadingColor = newColor;
        },
        updateNavRibbonBackgroundColor(state, newColor) {
            state.portal.navRibbonBackgroundColor = newColor;
        },
        updateNavRibbonFontColor(state, newColor) {
            state.portal.navRibbonFontColor = newColor;
        },
        updatePortalFontStyle(state, newStyle) {
            state.portal.portalFontStyle = newStyle;
        },
        updatePortalFontSize(state, newSize) {
            state.portal.portalFontSize = newSize;
        },
        updateLogoImageSrc(state, newImageSrc) {
            state.portal.logoSrc = newImageSrc;
        },
        updateBackgroundImageSrc(state, newImageSrc) {
            state.portal.backgroundImageSrc = newImageSrc;
        },
        updateSitemapBackgroundColor(state, newColor) {
            state.portal.sitemapBackgroundColor = newColor;
        },
        updateSitemapBackgroundOpacity(state, newOpacity) {
            state.portal.sitemapBackgroundOpacity = newOpacity;
        },
        updateSitemapBackgroundRGBColor(state, rgbObj) {
            state.portal.sitemapBackgroundRGBColor = rgbObj;
        },
        updateSitemapBackgroundRGBAString(state) {
            let rgbObj = state.portal.sitemapBackgroundRGBColor;
            let opacityVal = state.portal.sitemapBackgroundOpacity / 100;
            state.portal.sitemapBackgroundRGBAString = 'rgba(' + rgbObj.r + ', ' + rgbObj.g + ', ' + rgbObj.b + ',' + opacityVal + ')';
        },
        updateBackgroundChoice(state, newChoice) {
            state.portal.backgroundChoice = newChoice;
        },
        updateSolidBackgroundColor(state, newColor) {
            state.portal.solidBackgroundColor = newColor;
        },
        updateSitemapCornerRounding(state, newRounding) {
            state.portal.sitemapCornerRounding = newRounding;
            state.portal.sitemapCornerRoundingString = newRounding + "px";
        },

        updateMainTopicTitleAlignment(state, alignmentValue) {
            state.portal.mainTopicTitleAlignment = alignmentValue;
        },
        updateTextStylingBold(state, boldvalue) {
            state.portal.textStylingBold = boldvalue;
        },

        updateTextStylingItalic(state, italicvalue) {
            state.portal.textStylingItalic = italicvalue;
        },

        updateCustomCSS(state, CSSdata) {
            state.portal.customCSS = CSSdata;
        },

        toggleSidebar(state) {
            state.portal.sidebarCollapsed = !state.portal.sidebarCollapsed;
        },
        updateLinkTreeElements: (state, payload) => {
            console.log('updating');
            state.portal.linkTree = payload;
        },
        updateLinkTreeMainTopic: (state, payload) => {
            console.log('updating main link with index:' + payload.index);
            state.portal.linkTree[payload.index].subheadings = payload.value;
        },
        updateLinkTreeSubTopic: (state, payload) => {
            state.portal.linkTree[payload.mainIndex].subheadings[payload.subIndex].links = payload.value;
        },
        updateMainTopicHeading(state, payload) {
            state.portal.linkTree[payload.mainIndex].title = payload.value;
        },
        updateSubTopicHeading(state, payload) {
            state.portal.linkTree[payload.mainIndex].subheadings[payload.subIndex].title = payload.value;
        },
        deleteSubTopicHeading(state, payload) {
            state.portal.linkTree[payload.mainIndex].subheadings.splice([payload.subIndex], 1);
        },
        addSubTopicHeadingLink(state, payload) {
            var orderIndex = state.portal.linkTree[payload.mainIndex].subheadings[payload.subIndex].links.length + 1;
            var link = {
                active: true,
                url: "https://www.test.com",
                title: "Placeholder Link (Please edit me)",
                order: orderIndex,
                id: "Link-id-" + orderIndex,
                tableau_link: false
            };
            state.portal.linkTree[payload.mainIndex].subheadings[payload.subIndex].links.push(link);
        },
        updateManagerLoginState(state, payload) {
            if (payload.success) {
                state.manager.isLoggedIn = true;
                state.manager.userName = payload.user_data.user_name;
                state.manager.proxy_list = payload.proxy_list;
            } else {
                state.manager.isLoggedIn = false;
            }
        },
        updateTableauLoginState(state, payload) {
            if (payload.data && payload.data.success) {
                state.tableau.isLoggedIn = true;
                state.tableau.loginToken = payload.data.token;
                if (payload.data.sites.error) {
                    state.tableau.siteList = [];
                } else {
                    state.tableau.siteList = payload.data.sites;
                }
                state.tableau.userName = payload.user_name;
                //state.tableau.proxy_list = payload.proxy_list;
            } else {
                state.tableau.isLoggedIn = false;
            }
        },
        updateTableauSiteData(state, payload) {
            if (payload) {
                state.tableau.siteData = [payload.data];
                console.log('data updating', payload.data);
            } else {
                state.tableau.siteData = [];
            }
        },
        addLinkTreeHeading(state) {
            state.portal.linkTree.push(
                {
                    id: state.portal.linkTree.length + 1,
                    title: 'New Heading ' + (state.portal.linkTree.length + 1),
                    order: 1,
                    subheadings: []
                }
            )
        },
        deleteMainHeading(state, payload) {
            console.log(payload.message, payload.mainIndex);
            state.portal.linkTree.splice(payload.mainIndex, 1);
        },
        addSubheading(state, payload) {
            var tmpIndex = state.portal.linkTree[payload.mainIndex].subheadings.length + 1;
            let newSubheading = {
                id: tmpIndex,
                title: "Sub Heading " + tmpIndex,
                order: tmpIndex,
                links: []
            }
            state.portal.linkTree[payload.mainIndex].subheadings.splice(tmpIndex - 1, 1, newSubheading);
        },
        toggleNavPreview(state) {
            state.portal.showNavBar = !state.portal.showNavBar;
        },
        updateManagerSavedPortalConfigs(state, configs) {
            state.manager.savedPortalConfigs = configs;
        },
        updateManagerSavedPortalConfigsAtIdx(state, payload) {
            state.manager.savedPortalConfigs[payload.idx].config = JSON.stringify(payload.config);
            state.manager.savedPortalConfigs[payload.idx].last_updated = payload.last_updated;
        },
        updateManagerSavedPortalPublishedStatusForUuid(state, payload) {
            state.manager.savedPortalConfigs[payload.idx].published = payload.published;
            state.manager.savedPortalConfigs[payload.idx].last_updated = payload.last_updated;
        },
        loadPortalConfig(state, idx) {
            state.manager.selectedPortalIndex = idx;
            state.manager.portal_is_dirty = false;
            state.portal = JSON.parse(state.manager.savedPortalConfigs[idx].config);
        },
        setPortalConfig(state, payload) {
            state.portal = JSON.parse(payload);
            state.portal.published = true;
        },
        saveLatestDebounce(state, ongoingRequest) {
            state.manager.ongoingRequest = ongoingRequest;
        },
        savingPortalConfig(state, savingPortalConfig) {
            state.manager.savingPortalConfig = savingPortalConfig;
        },
        updateBusy(state, isBusy) {
            if (typeof isBusy == "string") {
                state.manager.isBusyBoolean = true;
                state.manager.isBusyString = isBusy;
            } else {
                state.manager.isBusyBoolean = false;
                state.manager.isBusyString = isBusy;
            }
        },
        removePortalConfigWithUUID(state, uuid) {
            let idx = state.manager.savedPortalConfigs.map(function(e) { return e.uuid; }).indexOf(uuid);
            state.manager.savedPortalConfigs.splice(idx, 1);
            state.manager.selectedPortalIndex = undefined;
            state.portal = '';
        },
        insertNewPortalConfig(state, newPortalConfig) {
            state.manager.savedPortalConfigs.push(newPortalConfig);
            state.manager.portal_is_dirty = false;
            state.portal = JSON.parse(newPortalConfig.config);
            state.manager.selectedPortalIndex = state.manager.savedPortalConfigs.length - 1;
        },
        applyTemplateConfigs(state, payload){
            let newConfig = {...state.portal, ...payload}
            console.log('newMergedConfig', newConfig);
            state.portal = newConfig;
        }
    }
});