<template>
  <b-col class="sidebar" v-bind:class="{ 'sidebar-collapsed': sidebarCollapsed }">
    <img class="brilliant-spaces-logo" src="../assets/images/brilliant_spaces_logo.svg" />
    <manager-login-status></manager-login-status>
    <template v-if="isLoggedIn">
      <div role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1 accordion-header" role="tab">
            <a href="#" v-b-toggle.accordion-panel-1 variant="info">
              Look &amp; Feel
              <span class="open">[ - ]</span>
              <span class="closed">[ + ]</span>
            </a>
          </b-card-header>
          <b-collapse id="accordion-panel-1" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <design-properties-form ref="designPropertiesForm"></design-properties-form>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1 accordion-header" role="tab">
            <a href="#" v-b-toggle.accordion-panel-2 variant="info">
              <span class="open">[ - ]</span>
              <span class="closed">[ + ]</span>
              Page Elements
            </a>
          </b-card-header>
          <b-collapse id="accordion-panel-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <page-elements-form></page-elements-form>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1 accordion-header" role="tab">
            <a href="#" v-b-toggle.accordion-panel-3 variant="info">
              <span class="open">[ - ]</span>
              <span class="closed">[ + ]</span>
              Tableau Server Links
            </a>
          </b-card-header>
          <b-collapse id="accordion-panel-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <template v-if="!isLoggedIn">Please login to Manager first</template>
              <template v-else>
                <tableau-server-login-form></tableau-server-login-form>
              </template>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </template>
  </b-col>
</template>

<script>
import DesignPropertiesForm from "../components/DesignPropertiesForm.vue";
import ManagerLoginStatus from "../components/ManagerLoginStatus.vue";
import PageElementsForm from "../components/PageElementsForm.vue";
import TableauServerLoginForm from "./TableauServerLoginForm.vue";

export default {
  name: "Sidebar",
  components: {
    DesignPropertiesForm,
    PageElementsForm,
    ManagerLoginStatus,
    TableauServerLoginForm
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.manager.isLoggedIn && typeof this.$store.state.manager.selectedPortalIndex != 'undefined';
    },
    sidebarCollapsed: function() {
      return this.$store.state.portal.sidebarCollapsed;
    }
  }
};
</script>
